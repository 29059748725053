import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2020-03-17T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Greeble:`}</strong>{` The action of making something look more technologically advanced than it really is, it can be as simple as a couple of buttons or a shiny thing.`}</p>
    <p>{`Ref: `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Greeble"
      }}>{`Wikipedia`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      